<template>
  <div>
    <v-simple-table
      class="table-padding-2"
      fixed-header
      height="calc(100vh - 205px)"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 150px">
              <InputFilter
                :label="$t('labels.code')"
                :placeholder="$t('labels.code')"
                name="code"
                sort-name="code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th class="text-center" style="width: 280px">
              <SelectFilter
                :label="$t('labels.domestic_province')"
                :placeholder="$t('labels.domestic_province')"
                name="id_delivery_contract_provincial"
                sort-name="id_delivery_contract_provincial"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="contractOptions"
              />
            </th>
            <th class="text-center" style="width: 280px">
              <SelectFilter
                :label="$t('labels.inter_province')"
                :placeholder="$t('labels.inter_province')"
                name="id_delivery_contract_inter_provincial"
                sort-name="id_delivery_contract_inter_provincial"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="contractOptions"
              />
            </th> -->
            <th class="text-center" style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="yesNoOptions"
              />
            </th>
            <th class="text-center" style="width: 100px">
              <v-btn color="primary" small @click="addItem">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <v-text-field
                class="c-input-small"
                :class="{ 'input-disabled-normal': item.editing }"
                v-model="item.code"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing || !!item.id"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                class="c-input-small"
                v-model="item.name"
                dense
                outlined
                single-line
                hide-details
                :disabled="!item.editing"
              ></v-text-field>
            </td>
            <!-- <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_delivery_contract_provincial"
                :items="contractOptions"
                :disabled="!item.editing"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_delivery_contract_inter_provincial"
                :items="contractOptions"
                :disabled="!item.editing"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </td> -->
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="saveShopContract(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        v-if="totalPage > 1"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "Shop",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    contracts: [],
    filters: {},
    yesNoOptions: [...YES_NO_OPTIONS],
    page: 1,
    totalPage: 1,
  }),
  computed: {
    contractOptions() {
      return [...this.contracts].map((contract) => {
        const txts = [];
        txts.push(contract.delivery_company_short_name);
        txts.push(". ");
        txts.push(this.$t("labels.account"));
        txts.push(": ");
        txts.push(contract.account_info.company_id);
        txts.push(" - ");
        txts.push(contract.account_info.customer_id);
        if (!contract.status) {
          txts.push(` (${this.$t("labels.deactive")})`);
        }
        return {
          text: txts.join(""),
          value: contract.id,
        };
      });
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  async mounted() {
    this.getList();
    this.getContract();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        code: null,
        name: null,
        // id_delivery_contract_provincial: null,
        // id_delivery_contract_inter_provincial: null,
        status: 1,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async getList() {
      try {
        const { data } = await httpClient.post("/shop-list", {
          ...this.filters,
          page: this.page,
        });
        this.totalPage = data.totalPage;
        this.items = [...data.rows];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getContract() {
      try {
        const { data } = await httpClient.post("/all-delivery-contract", {
          ...this.filters,
          page: this.page,
        });
        this.contracts = [...data].map((item) => ({
          ...item,
          account_info: JSON.parse(item.account_info),
        }));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async saveShopContract(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-shop", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>
